@import url(https://fonts.googleapis.com/css?family=Poppins);
.main_side_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.container_header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.side_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0px 24px;
}
.tag_info{
    padding: 24px;
}
#logo {
    flex-grow: 2;
}

.header_labels {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 44%;
}

body {
    font-family: 'Poppins', sans-serif;
    padding: 0px;
    margin: 0px;
    max-width: 100vw;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

#loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: white;
    z-index: 999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#spinner {
    -webkit-animation: rotate 0.56s infinite linear;
            animation: rotate 0.56s infinite linear;
    width: 50px;
    height: 50px;
    border: 12px solid rgb(255, 44, 90);
    border-bottom: 12px solid #fff;
    border-radius: 50%;
    margin: 0;
}

@-webkit-keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

header {
    width: 92vw;
    padding: 6vh 4vw;
    font-weight: bold;
    background: transparent;
    color: rgb(255, 44, 90);
    transition: 0.4s ease-in-out;
    z-index: 2;
}

header table {
    width: 100%;
}

#navigation {
    width: 80%;
}

#magnify {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.92);
    position: fixed;
    z-index: 4;
    display: none;
    flex-direction: column;
    justify-content: center;
}

#magnify h1 {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px 40px;
    color: #fff;
    transition: 0.4s ease-in-out;
}

#magnify h1:hover {
    color: rgb(255, 44, 90);
    cursor: pointer;
}

#img_here {
    width: 90%;
    height: 90%;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    margin: 0px auto;
}

#logo {
    font-size: 24px;
    color: rgb(255, 44, 90);
}

.navigation {
    text-align: right;
}

.navigation a {
    color: inherit;
    text-decoration: none;
    padding: 5px 10px;
    border-bottom: 2px solid transparent;
    margin-left: 4vw;
    transition: 0.4s ease-in-out;
}

.navigation a:hover {
    border-bottom: 2px solid #fff;
}

#top_part {
    width: 100%;
    border: 2vh solid #fff;
}

#top_part tr td {
    width: 50%;
}

#about {
    width: 84% !important;
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 0px 8%;
}

#about h1 {
    font-size: 45px;
    word-wrap: break-word;
}

#about table {
    width: 70%;
    margin-top: 4vh;
}

#about table tr td {
    width: 20% !important;
    text-align: left;
}

#mobile_image {
    display: none;
}

.social {
    color: rgb(255, 44, 90);
    font-size: 23px;
    padding: 12px 17px;
    border-radius: 50%;
    background: transparent;
    transition: 0.4s ease-in-out;
    font-weight: bold;
    margin: 0px 8px;
}

.social:hover {
    cursor: pointer;
    color: #fff;
    background: rgb(255, 44, 90);
    box-shadow: 0px 10px 30px rgba(255, 66, 107, 0.8);
}

.btn_one {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    color: rgb(255, 44, 90);
    background: #fff;
    border: 3px solid rgb(255, 44, 90);
    padding: 8px 40px;
    width: 35%;
    border-radius: 80px;
    font-weight: bold;
    margin: 2vh 0px;
    transition: 0.4s ease-in-out;
    text-decoration:none;
}

.btn_one:hover {
    box-shadow: 0px 10px 30px rgba(255, 44, 90, 0.8);
    cursor: pointer;
    color: #fff;
    background: rgb(255, 44, 90);
    text-decoration:none;
}

#rightImage {
    width: 100%;
    height: 100vh;
    background: linear-gradient(0deg, rgba(255, 44, 90, 0.8), rgba(2255, 44, 90, 0.8)), url(/static/media/logo.afe1abd5.jpg) center center;
    background-size: cover;
    background-repeat: no-repeat;
}

#work {
    width: 92vw;
    padding: 6vh 4vw;
    font-weight: bold;
}

#work h1 {
    padding: 5px 0px;
    border-bottom: 4px solid rgb(255, 44, 90);
    width: 6%;
}

#photos {
    width: 100%;
    -webkit-columns: 3;
            columns: 3;
    grid-column-gap: 4%;
    -webkit-column-gap: 4%;
            column-gap: 4%;
    z-index: 1;
    transition: 0.4s ease-in-out;
}

#photos img {
    width: 100%;
    height: auto;
    margin: 4.5% 0px;
    transition: 0.4s ease-in-out;
}

#photos img:hover {
    transform: scale(1.1);
    cursor: pointer;
}

#bio {
    width: 92vw;
    padding: 6vh 4vw;
    font-weight: bold;
}

#bio h1 {
    padding: 5px 0px;
    border-bottom: 4px solid rgb(255, 44, 90);
    width: 8%;
}

#contact {
    width: 92vw;
    padding: 6vh 4vw;
}

#contact h1 {
    padding: 5px 0px;
    border-bottom: 4px solid rgb(255, 44, 90);
    width: 10%;
}

#contact table {
    width: 100%;
}

#contact table tr td {
    width: 50%;
}

#inner_div table {
    width: 100%;
}

#inner_div table tr td {
    font-weight: bold;
}

#inner_table tr td {
    padding: 10px 20px !important;
}

#contact form {
    width: 100%;
}

#contact form input {
    width: 96%;
    margin: 20px 1%;
    background: transparent;
    border: 0px;
    border-bottom: 3px solid rgba(255, 44, 90, 0.5);
    padding: 8px 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    transition: 0.4s ease-in-out;
    color: #000;
    font-weight: bold;
}

#contact form textarea {
    width: 96%;
    margin: 20px 1%;
    padding: 8px 10px;
    border: 0px;
    border-bottom: 3px solid rgba(255, 44, 90, 0.5);
    padding: 8px 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    background: transparent;
    resize: none;
    transition: 0.4s ease-in-out;
    color: #000;
    font-weight: bold;
}

#contact form input:focus {
    outline: none;
    border-bottom: 3px solid rgba(255, 44, 90, 1);
}

#contact form textarea:focus {
    outline: none;
    border-bottom: 3px solid rgba(255, 44, 90, 1);
}

#address {
    position: relative;
    top: -25px;
    left: 30px;
}

#footer {
    width: 100%;
    padding: 10vh 0px;
    text-align: center;
    font-weight: bold;
}

#footer a {
    color: rgb(255, 44, 90);
    text-decoration: none;
}

::-webkit-input-placeholder {
    color: #000;
}

:-ms-input-placeholder {
    color: #000;
}

::-ms-input-placeholder {
    color: #000;
}

::placeholder {
    color: #000;
}

button:focus {
    outline: none;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0);
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.8);
}

@media (max-width: 700px) {
    #mobile_image {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #photos {
        -webkit-columns: 1;
                columns: 1;
        grid-column-gap: 0%;
        -webkit-column-gap: 0%;
                column-gap: 0%;
    }

    #photos img {
        margin: 2% 0px;
    }

    #photos img:hover {
        transform: scale(1);
        cursor: pointer;
    }

    #rightImage {
        display: none;
    }

    #top_part {
        border: 0px;
    }

    #top_part tr td {
        width: 100%;
        text-align: center;
    }

    #about {
        width: 92% !important;
        height: auto;
        padding-top: 81vh !important;
        text-align: center;
        padding: 0px 4%;
    }

    #about h1 {
        font-size: 30px !important;
        word-wrap: break-word;
    }

    #about table {
        width: 100%;
        margin-top: 6vh;
    }

    #about table tr td {
        text-align: center;
    }

    .btn_one {
        padding: 6px 30px;
        width: 60%;
        margin: 4vh auto;
        box-shadow: 0px 10px 20px rgba(255, 44, 90, 0.8);
        background: rgb(255, 44, 90);
        color: #fff;
        text-decoration:none;
    }

    .btn_one:hover {
        box-shadow: 0px 10px 40px rgba(255, 44, 90, 0.8);
        cursor: pointer;
        text-decoration:none;

    }

    #contact table tr td {
        width: 100%;
        display: block;
    }

    #inner_table tr td {
        padding: 10px 0px !important;
    }

    #contact form input {
        width: 90%;
    }

    #contact form textarea {
        width: 90%;
    }

    #work h1 {
        width: 19%;
    }

    #bio h1 {
        width: 25%;
    }

    #contact h1 {
        width: 33%;
    }

    header {
        width: 96vw;
        background: #fff;
        padding: 4vh 2vw;
    }

    #header table tr td {
        width: 100%;
        display: block;
        text-align: center;
        padding: 15px 0px;
    }

    .navigation a {
        color: #000;
    }

    .container_header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .header_labels {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }

    .side_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0px 4px;
    }

    .main_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

